import http from '../http';

export function addProject (data) {
    return http({
        url: "/api/project/project",
        method: "put",
        data:data
    })
}
export function editProject (data) {
    return http({
        url: "/api/project/project",
        method: "post",
        data:data
    })
}
export function projectTeamChange (data) {
    return http({
        url: "/api/project/projectTeamChange",
        method: "post",
        data:data
    })
}
export function getDepartment (params) {
    return http({
        url: "/api/user/oneDepartment",
        method: "get",
        params:params
    })
}
export function moneySettleDetail (params) {
    return http({
        url: "/api/project/moneySettleDetail",
        method: "get",
        params:params
    })
}

export function getPost (params) {
    return http({
        url: "/api/user/twoDepartment",
        method: "get",
        params:params
    })
}
export function getUser (params) {
    return http({
        url: "/api/ltd/getUserFromStation",
        method: "get",
        params:params
    })
}



export function getProjectType (params) {
    return http({
        url: "/api/ltd/projectTypeAllList",
        method: "get",
        params:params
    })
}
export function getProjectDetail (params) {
    return http({
        url: "/api/project/project",
        method: "get",
        params:params
    })
}
export function getDepUser (params) {
    return http({
        url: "/api/user/departmentUserList",
        method: "get",
        params:params
    })
}
//获取父级项目
export function getParentPro (params) {
    return http({
        url: "/api/project/parentType",
        method: "get",
        params:params
    })
}


