<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"

    /><!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
        @keyWordMethod="keyWordMethod"
        @highKeyWordMethod="highKeyWordMethod"

    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @detileProject="detileProject"
        @passOrder="passOrder"
        @editProject="editProject"
        @discardProject="discardProject"

    />
    <!--addDialog 组件 -->
    <el-dialog
        :append-to-body="true"
        :title="addDialogData.title"
        :visible.sync="addDialogData.isDialog"
        :top="addDialogData.top"
        :width="addDialogData.width"
    >
      <div>
        <el-row>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-form ref="form"  label-width="120px">
                <el-form-item label="姓名">
                  {{addDialogData.addDialogShowData.name}}
                </el-form-item>
                <el-form-item label="部门">
                  {{addDialogData.addDialogShowData.department_name}}
                </el-form-item>
                <el-form-item label="岗位名称">
                  {{addDialogData.addDialogShowData.station_name}}
                </el-form-item>
                <el-form-item label="累计获得成长值">
                  {{addDialogData.addDialogShowData.my_sum_grow_value}}
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <el-form ref="form"  label-width="160px">
                <el-form-item label="工号">
                  {{addDialogData.addDialogShowData.user_id}}
                </el-form-item>
<!--                <el-form-item label="职级">-->
<!--                  {{addDialogData.addDialogShowData.my_sum_grow_value}}-->
<!--                </el-form-item>-->
                <el-form-item label="初始成长值">
                  {{addDialogData.addDialogShowData.growth_value}}
                </el-form-item>
                <el-form-item label="合计成长值">
                  {{addDialogData.addDialogShowData.my_all_grow_value}}
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
        <div>
          <common-table
              :tableData="tableDataDetile"
              :tableProp="tablePropDetile"
          />
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import {timer} from '@/assets/js/comment'
import {getGrowth, getDetile} from '@/api/project_management/growth_value_summary'
import {getProjectType} from "../../../api/project_management/new_project";

export default {
  components: {},
  props: [],
  data() {
    return {
      //title 数据源
      titleProp: {
        name: '成长值汇总表',//标题名称
        butOperate: []
      },
      //tabs 数据源
      tabsProp: {
        active: '0',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: false,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索循环体
        highKeyWord: [{
          name: '项目名称',
          field: 'projectName',
          type: 1,
        },  {
          name: '项目类型',
          field: 'typeId',
          type: 3,
        },{
          name: '状态',
          field: 'status',
          type: 3,
        }],
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取
        typeIdOptions: [],
        statusOptions: [
          {value: '-1', label: '作废'},
          {value: '0', label: '待成单'},
          {value: '1', label: '成单'},
          {value: '2', label: '不成单'},
          {value: '3', label: '已指派（进行中，待完工）'},
          {value: '4', label: '完工待验收'},
          {value: '5', label: '验收'},
          {value: '6', label: '不通过'},
        ],

        //高级搜索的字段
        heighKewWordField: {status: '', projectName: '', typeId: ''},
        //tabs 标题
        tabsData: [{
          label: '全部',
          name: '0',
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '工号',
          field: 'user_id',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '姓名',
          field: 'user_info',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name'
        }, {
          title: '部门',
          field: 'department_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '职级',
          field: 'class_of_positions',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '岗位名称',
          field: 'station_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '初始成长值',
          field: 'growth_value',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '获得成长值',
          field: 'sum_grow_value',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '合计成长值',
          field: 'final_grow_value',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '详情',
            type: 'primary',
            method: 'detileProject',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      tablePropDetile: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '项目名称',
          field: 'project',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'project_title',
        }, {
          title: '项目总成长值',
          field: 'project_grow_value',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '获得成长值',
          field: 'my_grow_value',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '完成日期',
          field: 'complete_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }],
      },
      //addDialog数据源
      addDialogData: {
        isDialog: false,//dialog是否显示
        subMethod: 'addDialogSub',//点击确定方法名
        title: '成长值详情',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 显示数据
        addDialogShowData: {
          name: '',
          user_id: '',
          department_name: '',
          station_name: '',
          growth_value: '',
          my_all_grow_value: '',
          my_sum_grow_value: '',
        },
      },
      //表格数据
      tableData: [],
      tableDataDetile: [],
      //addDialog提交数据
      subData: {}

    };
  },
  created() {
    this.initLoad();
    this.getProjectType();

  },
  mounted() {
  },
  methods: {
    getProjectType(){
      getProjectType().then(res=>{
        console.log(res)
        this.tabsProp.typeIdOptions=res.data.map(item => ({
          label: item.type_name,
          value:(item.id).toString()
        }))
      })
    },
    start_timeChange() {
      this.addDialogData.addDialogShowData.cycle_time = timer(this.addDialogData.addDialogShowData.end_time - this.addDialogData.addDialogShowData.start_time)
    },
    end_timeChange() {
      this.addDialogData.addDialogShowData.cycle_time = timer(this.addDialogData.addDialogShowData.end_time - this.addDialogData.addDialogShowData.start_time)
    },
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
      }
      if(this.tabsProp.heighKewWordField.projectName!=""){
        params.projectName = this.tabsProp.heighKewWordField.projectName
      }
      if(this.tabsProp.heighKewWordField.status!=""){
        params.status = this.tabsProp.heighKewWordField.status
      }
      if(this.tabsProp.heighKewWordField.typeId!=""){
        params.typeId = this.tabsProp.heighKewWordField.typeId
      }
      getGrowth(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].all_grow_value ? '' : res.data[i].all_grow_value = 0
          res.data[i].growth_value? '' : res.data[i].growth_value = 0
          res.data[i].all_grow = parseInt(res.data[i].all_grow_value) + parseInt(res.data[i].growth_value)
        }
        this.tableData = res.data
      })
    },
    //tabs 切换点击
    tabsClick(tab) {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(tab.name)
    },
    //tabs 点击搜索
    keyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.keywords);//搜索的值
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.heighKewWordField);//高级搜索的字段
    },

    //table 详情 按钮点击
    detileProject(row, index) {
      console.log(row)
      console.log(index)
      console.log('详情 点击')
      let params = {user_id: row.user_id}
      getDetile(params).then((result)=>{
        console.log(result)
        this.addDialogData.isDialog = true
        this.addDialogData.addDialogShowData.name = result.data.userInfo.user_info.name
        this.addDialogData.addDialogShowData.user_id = result.data.userInfo.user_info.user_id
        this.addDialogData.addDialogShowData.department_name = result.data.userInfo.department_name
        this.addDialogData.addDialogShowData.station_name = result.data.userInfo.station_name
        this.addDialogData.addDialogShowData.my_sum_grow_value = result.data.userInfo.my_sum_grow_value
        this.addDialogData.addDialogShowData.growth_value = result.data.userInfo.growth_value
        this.addDialogData.addDialogShowData.my_all_grow_value = result.data.userInfo.my_all_grow_value

        this.tableDataDetile = result.data.data
      })
      .catch((error) => {
        console.log(error);
        this.$message({
          message: error.msg,
          type: 'error'
        });
      })
    },
    //table 确认成单 按钮点击
    passOrder(row, index) {
      console.log(row)
      console.log(index)
      console.log('确认成单 点击')
    },
    //table 修改 按钮点击
    editProject(row) {
      this.addDialogData.addDialogShowData = this.$options.data().addDialogData.addDialogShowData;
      this.addDialogData.title = '修改';
      let params = {id: row.id}
      console.log(params)
      // projectDetial(params).then((result) => {
      //   let res = result.data;
      //
      //   res.is_external = res.project_price.is_external
      //   res.source_cost = res.project_price.source_cost
      //   res.market_cost = res.project_price.market_cost
      //   res.external_cost = res.project_price.external_cost
      //   res.attachment_json = JSON.parse(res.attachment_json)
      //   res.end_time = res.end_time * 1000
      //   res.start_time = res.start_time * 1000
      //
      //   this.addDialogData.addDialogShowData = res
      //   console.log(this.addDialogData.addDialogShowData)
      // })
      this.addDialogData.isDialog = true;
    },
    //table 作废 按钮点击
    discardProject(row, index) {
      console.log(row)
      console.log(index)
      console.log('作废 点击')
    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
    // addDialog 点击确定
    addDialogSub() {
      this.subData = {...this.addDialogData.addDialogShowData}  //ES6深拷贝
      this.subData.start_time = this.subData.start_time / 1000;
      this.subData.end_time = this.subData.end_time / 1000;
      this.subData.attachment_json = JSON.stringify(this.subData.attachment_json);

      // editProject(this.subData).then((result) => {
      //   this.addDialogData.isDialog = false;
      //   this.$message({
      //     message: result.msg,
      //     type: 'success'
      //   });
      // })
      //     .catch((error) => {
      //       console.log(22);
      //       this.$message({
      //         message: error.msg,
      //         type: 'error'
      //       });
      //     })
    },


  },
};
</script>
<style lang="scss" scoped></style>
