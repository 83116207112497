import http from '../http';

export function getGrowth (params) {
    return http({
        url: "/api/project/growthPage",
        method: "get",
        params:params
    })
}

export function getDetile (params) {
    return http({
        url: "/api/project/growthDetail",
        method: "get",
        params:params
    })
}